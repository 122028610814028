import { useQuery } from "@tanstack/react-query";
import apiClient from "../hooks/apiClient";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import Select from "react-dropdown-select";
import SVGIcon from "./SVGIcon";

import "./SearchDropdown.scss";
import Button from "./Button";
import ctl from "@netlify/classnames-template-literals";
import { useEffect, useMemo, useState, useCallback } from "react";
import Shimmer from "./Shimmer";
import useDebounce from "../hooks/useDebounce";
import {
  dropdownKeyList,
  focusOnElement,
  onKeyPressDropdownTrigger,
} from "../helper/keyboardAccessHelper";

interface ISelectedDropdown {
  organizationName?: string;
  name: string;
  id: string;
  code?: string;
  refCode?: string;
}

interface ISearchDropdown {
  disabled?: boolean;
  selected: ISelectedDropdown | undefined | null;
  fetchData?: {
    searchUrl?: string;
    params?: any;
  };
  id: string;
  onTab?: () => void;
  onShiftTab?: () => void;
  onChange: (val: ISelectedDropdown | undefined) => void;
  isRequired?: boolean;
  label?: string;
  widthClass?: string;
  widthStyle?: string;
  onSuccess?: () => void;
  tabIndex?: number;
  addAction?: {
    text: string;
    iconName: string;
    onClick: (searchValue: string) => void;
  };
  additionalQueryKey?: string[];
  placeholder?: string;
  initialData?: any;
  setDropdownDataList?: (val: any) => void;
  keyData?: string;
  isHorizontal?: boolean;
  labelCustomClass?: string;
  wrapperCustomClass?: string;
  customClass?: string;
  customStyle?: Object;
  keyItem?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  insideElement?: JSX.Element | React.ReactNode;
  useClearButton?: boolean;
  useSearchValueToUrl?: boolean;
  maxOption?: number | null;
  ableToAddNewOption?: boolean;
  customDropdownOption?: (option: any, index: number) => JSX.Element;
  customDropdownHeader?: JSX.Element;
  customContent?: ({ props }: any) => JSX.Element;
  usePortal?: boolean;
  useSelectedWithTag?: boolean;
  isFullWidth?: boolean;
}

const SearchDropdown = ({
  disabled,
  selected,
  fetchData,
  id,
  onChange,
  isRequired,
  label,
  widthClass = "",
  widthStyle = "",
  onSuccess,
  onTab,
  onShiftTab,
  tabIndex = -1,
  addAction,
  isHorizontal = false,
  labelCustomClass,
  wrapperCustomClass,
  customClass = "",
  customStyle = {},
  keyItem = "name",
  additionalQueryKey,
  placeholder,
  initialData = null,
  setDropdownDataList,
  keyData,
  errorMessage,
  showErrorMessage,
  insideElement,
  useClearButton,
  useSearchValueToUrl = false,
  maxOption = 4,
  ableToAddNewOption = false,
  customDropdownOption,
  customDropdownHeader,
  customContent,
  usePortal = true,
  useSelectedWithTag = false,
  isFullWidth = false,
}: ISearchDropdown) => {
  const [searchValue, setSearchValue] = useState("");
  const [optionsMaxHeight, setOptionsMaxHeight] = useState<number>(18.75);
  const [dropdownHeaderRect, setDropdownHeaderRect] = useState<DOMRect>();

  const handleDropdownRect = useCallback((node: HTMLDivElement) => {
    setDropdownHeaderRect(node?.getBoundingClientRect());
  }, []);

  const getData = async () => {
    if (!fetchData?.searchUrl) return;
    const _searchUrl = useSearchValueToUrl
      ? `${fetchData?.searchUrl}/${searchValue}`
      : fetchData?.searchUrl;
    return apiClient(_searchUrl, "GET", {
      params: fetchData?.params,
      isShowErrorToast: false,
    });
  };

  const generateQueryKey = () => {
    const _key = [id];
    additionalQueryKey && _key.push(...additionalQueryKey);
    useSearchValueToUrl && !isEmpty(searchValue) && _key.push(searchValue);
    return _key;
  };

  const memoQueryKey = useMemo(
    () => generateQueryKey(),
    [additionalQueryKey, searchValue]
  );
  const debouncedMemoQueryKey = useDebounce(memoQueryKey, 500);
  const isEnableQuery = useMemo(
    () =>
      !disabled &&
      !initialData &&
      !!fetchData &&
      (useSearchValueToUrl ? !isEmpty(searchValue) : true),
    [disabled, initialData, fetchData, useSearchValueToUrl, searchValue]
  );

  const { data: dataQuery, isFetching } = useQuery({
    queryKey: debouncedMemoQueryKey,
    queryFn: getData,
    staleTime: 5 * 1000,
    gcTime: 5 * 1000,
    enabled: isEnableQuery,
    refetchOnWindowFocus: false,
  });
  const _dataQuery = initialData ? initialData : dataQuery?.data;
  const list = (keyData ? _dataQuery?.[keyData] : _dataQuery) || [];
  const onSearch = ({ props, state, methods }: any) => {
    const _seachValue = state.search?.toLowerCase();
    setSearchValue(_seachValue);

    if (useSearchValueToUrl) return list;
    let searchResult = cloneDeep(list)?.filter((item: ISelectedDropdown) =>
      //@ts-ignore
      item?.[keyItem]?.toLowerCase()?.includes(_seachValue)
    );
    if (maxOption) {
      searchResult = searchResult?.slice(0, maxOption);
    }
    return searchResult;
  };

  const _onChange = (values: ISelectedDropdown[]) => {
    if (isEqual(selected, values?.[0])) return;
    onChange(values?.[0]);
    focusOnElement(id);
    onSuccess && onSuccess();
  };

  const customDropdownHandleRenderer = ({ props, state, methods }: any) => {
    return (
      <div className="flex items-center mt-[4px]">
        {useClearButton && selected && (
          <SVGIcon
            iconName="icon-cancel"
            size={16}
            fillColor="var(--n-300)"
            onClick={(e) => {
              e?.stopPropagation();
              onChange(undefined);
              setSearchValue("");
              methods?.clearAll && methods?.clearAll();
              state.search = "";
            }}
            customClass="mr-[0.5rem]"
          />
        )}

        <SVGIcon
          iconName={state?.dropdown ? "icon-arrow-up" : "icon-arrow-down"}
          size={16}
          fillColor="var(--n-300)"
          onClick={props.onClick}
        />
      </div>
    );
  };

  const setPosition = () => {
    const el = document.getElementsByName(id)?.[0];
    const rect = el?.getBoundingClientRect();
    var d = document.getElementsByClassName(
      "react-dropdown-select-dropdown"
    )?.[0];
    // @ts-ignore
    d.style.top = rect?.top + 24 + "px";
    // @ts-ignore
    d.style.left = rect?.left - 12 + "px";
  };

  const customLoadingDropdownRenderer = () => {
    return (
      <Shimmer
        customClass="m-[0.5rem]"
        heightClass="h-[1rem]"
        widthClass="w-[calc(100%-1rem)]"
      />
    );
  };

  const CustomDropdownRenderer = ({ props, state, methods }: any) => {
    const options =
      state?.search === "" || useSearchValueToUrl
        ? props?.options
        : state?.searchResults;

    const isShowAnotherOption = ableToAddNewOption && !!state?.search;

    const getPlaceholder = () => {
      if (isShowAnotherOption) return state?.search;
      if (useSearchValueToUrl) return "Please type your keyword first";
      return "No Option";
    };

    const PlaceholderCN = ctl(`
      typography-body px-[0.625rem] py-[0.5rem] ${
        useSearchValueToUrl && !isShowAnotherOption
          ? "text-n-400"
          : "text-n-700"
      }
      ${isShowAnotherOption && "cursor-pointer"}
    `);

    useEffect(() => {
      if (state?.cursor === options?.length) {
        if (addAction?.text) {
          document
            .getElementById("add-button")
            ?.scrollIntoView({ block: "nearest" });
          focusOnElement("add-button");
        } else {
          focusOnElement(id);
        }
      } else {
        document
          .getElementById(`${id}+option-${state?.cursor}`)
          ?.scrollIntoView({ block: "nearest" });
        focusOnElement(`${id}+option-${state?.cursor}`);
      }
    }, [state?.cursor]);

    return (
      <div
        className="flex flex-col w-full"
        data-testid={`${id}+options-container`}
      >
        {customDropdownHeader && (
          <div
            className="px-[0.625rem] py-[0.5rem] bg-n-100"
            ref={handleDropdownRect}
          >
            {customDropdownHeader}
          </div>
        )}
        <div
          className="flex flex-col w-full min-h-[2.3125rem] overflow-auto"
          style={{ maxHeight: `${optionsMaxHeight}rem` }}
        >
          {options?.length === 0 && (
            <div
              className={PlaceholderCN}
              onClick={() =>
                ableToAddNewOption &&
                methods.addItem({ [keyItem]: state?.search })
              }
            >
              {getPlaceholder()}
            </div>
          )}
          {options?.map((opt: any, index: number) => (
            <span
              role="option"
              aria-selected={state?.cursor === index ? "true" : "false"}
              aria-label="search-dropdown"
              tabIndex={-1}
              className={`typography-body text-n-700 px-[0.625rem] py-[0.5rem] hover:bg-b-100 w-full cursor-pointer ${
                state?.cursor === index ? "bg-b-100" : ""
              }`}
              onClick={() => methods.addItem(opt)}
              id={`${id}+option-${index}`}
              data-testid={`${id}+option-${index}`}
            >
              {customDropdownOption
                ? customDropdownOption(opt, index)
                : opt?.[keyItem]}
            </span>
          ))}
          {addAction?.text && (
            <div className="w-full flex items-center justify-start py-[0.25rem]">
              <Button.Secondary
                id="add"
                wrapperCustomClass={
                  state?.cursor === options?.length
                    ? "border border-solid border-b-400 p-[0.1875rem]"
                    : ""
                }
                customClass="py-[0.375rem] px-[1rem] gap-[0.25rem]"
                typographyClass="typography-body-bold"
                onClick={() => addAction?.onClick(state?.search)}
                noPadding={true}
              >
                {addAction?.iconName && (
                  <SVGIcon
                    iconName={addAction?.iconName}
                    size={16}
                    fillColor="var(--b-400)"
                  />
                )}
                {addAction?.text}
              </Button.Secondary>
            </div>
          )}
        </div>
      </div>
    );
  };

  const CustomSelectComponent = ({ props, state, methods }: any) => {
    const { values } = props || {};

    if (values?.length === 0)
      return <p className="text-n-300 typography-body">Please Select</p>;

    return (
      <div className="pl-[0.25rem] pr-[0.375rem] py-[0.25rem] bg-n-100 rounded-[0.25rem] typography-caption text-n-500 flex items-center gap-[0.25rem]">
        {values?.[0]?.[keyItem]}
        <SVGIcon
          iconName="icon-cancel"
          size={10}
          fillColor="var(--n-300)"
          onClick={(e) => {
            e?.stopPropagation();
            onChange(undefined);
            setSearchValue("");
            methods?.clearAll && methods?.clearAll();
            state.search = "";
          }}
        />
      </div>
    );
  };

  const width = widthStyle || widthClass?.replace("w-[", "")?.replace("]", "");

  const InputStyle = {
    height: "2.625rem",
    padding: "0.375rem 0.625rem",
    borderRadius: "0.5rem",
    ...(!isFullWidth && width ? { width } : {}),
    ...(disabled
      ? {
          backgroundColor: "var(--n-100)",
          opacity: "1",
          textColor: "var(--n-500)",
        }
      : { backgroundColor: "var(--n-000)", border: "1px solid var(--n-300)" }),
    ...customStyle,
  };

  // const onSpace = ({ props, state, methods, setState }: any) => {
  //   if (state.dropdown) {
  //     const options =
  //       state?.search === "" || useSearchValueToUrl
  //         ? props?.options
  //         : state?.searchResults;
  //     if (isNull(state?.cursor) || state?.cursor === options?.length) {
  //       addAction?.onClick(state?.search);
  //       state?.dropdown && setState({ dropdown: false });
  //     } else {
  //       methods.addItem(options[state.cursor]);
  //     }
  //   }
  // };

  const onKeyDown = ({ event, props, state, methods, setState }: any) => {
    const keyList = dropdownKeyList.filter((key) => key !== "Space");

    onKeyPressDropdownTrigger(
      event,
      id ?? "",
      state.dropdown,
      () => setState({ dropdown: true }),
      () => setState({ dropdown: false }),
      disabled ?? false,
      {
        // onSpace: () => onSpace({ props, state, methods, setState }),
        ...(onTab ? { onTab } : {}),
        ...(onShiftTab ? { onShiftTab } : {}),
      },
      keyList
    );
  };

  useEffect(() => {
    dataQuery?.isSuccess &&
      !isFetching &&
      setDropdownDataList &&
      setDropdownDataList(dataQuery?.data);
  }, [dataQuery?.data, dataQuery?.isSuccess, isFetching]);

  useEffect(() => {
    if (!customDropdownHeader) return;
    const newMaxHeight =
      18.75 -
      (dropdownHeaderRect?.height ? dropdownHeaderRect?.height / 16 : 0);
    setOptionsMaxHeight(newMaxHeight);
  }, [dropdownHeaderRect]);

  const wrapperCN = ctl(`
    ${isHorizontal && "flex"}
    ${isHorizontal && label && "gap-[0.75rem]"}
    ${wrapperCustomClass}
    ${isFullWidth && "w-full"} 
  `);

  const labelCN = ctl(`
    typography-body-bold text-n-700
    ${!isHorizontal && "mb-[0.25rem]"}
  `);

  const wrapperLabel = ctl(`
    flex items-center gap-[0.25rem] 
    ${isHorizontal ? "h-[2.625rem]" : "h-fit"}
    ${labelCustomClass}
  `);

  return (
    <div className={wrapperCN}>
      <div className={wrapperLabel}>
        {isRequired && (
          <div
            className={`h-[0.5rem] w-[0.5rem] rounded-full bg-r-400 ${
              !isHorizontal && "mb-[0.25rem]"
            }`}
            data-testid={`${id}-required-icon`}
          />
        )}
        {label && (
          <p className={labelCN} data-testid={`${id}-label`}>
            {label}
          </p>
        )}
      </div>
      <div
        className={`flex relative ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } ${isFullWidth ? "react-dropdown-full-width" : ""}`}
        data-testid={id}
      >
        <Select
          searchBy={keyItem}
          labelField={keyItem}
          valueField="id"
          //@ts-ignore
          values={selected?.[keyItem] ? [selected] : []}
          options={
            maxOption !== null && maxOption > 0
              ? list?.slice(0, maxOption)
              : list
          }
          onChange={(values) => _onChange(values)}
          dropdownHandleRenderer={customDropdownHandleRenderer}
          className={customClass}
          style={InputStyle}
          searchFn={onSearch}
          //@ts-ignore
          additionalProps={{ tabIndex, id }}
          handleKeyDownFn={onKeyDown}
          name={id}
          dropdownPosition="auto"
          dropdownRenderer={
            isFetching ? customLoadingDropdownRenderer : CustomDropdownRenderer
          }
          disabled={disabled}
          placeholder={placeholder}
          {...((customContent || useSelectedWithTag) && selected
            ? {
                contentRenderer: useSelectedWithTag
                  ? CustomSelectComponent
                  : customContent,
              }
            : {})}
          {...(usePortal
            ? {
                portal: document.getElementById("shared-dropdown") ?? undefined,
                onDropdownOpen: setPosition,
              }
            : { dropdownGap: 0 })}
        />
        {insideElement && insideElement}
      </div>
      {errorMessage && showErrorMessage && (
        <p
          className="typography-caption text-r-400 mt-[0.25rem]"
          data-testid={`${id}-error-message`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default SearchDropdown;
export type { ISearchDropdown, ISelectedDropdown };
