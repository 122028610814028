import { useReducer, useEffect } from "react";
import useKeyPress from "../hooks/useKeyPress";
import capitalize from "lodash/capitalize";
import InlineDialog, { useDialog } from "../design-system/InlineDialog";
import { focusOnElement } from "../helper/keyboardAccessHelper";
import { isNull } from "lodash";

const initialState = { selectedIndex: null };

const InlineDialogKeyPress = ({
  list,
  onChange,
  keyList,
  useCapitalize,
  menuCustomClass = "",
  widthBasedOnChildren = false,
}: any) => {
  const {
    open,
    setOpen,
    isForcedSelect = false,
    onTab,
    onShiftTab,
    id,
  } = useDialog() || {};

  const reducer = (state: any, action: any) => {
    if (!list) return;
    switch (action.type) {
      case "arrowUp":
        return {
          selectedIndex:
            state.selectedIndex !== 0 && state.selectedIndex !== null
              ? state.selectedIndex - 1
              : list?.length - 1,
        };
      case "arrowDown":
        return {
          selectedIndex:
            state.selectedIndex !== list?.length - 1 &&
            state.selectedIndex !== null
              ? state.selectedIndex + 1
              : 0,
        };
      case "reset":
        return {
          selectedIndex: null,
        };
      case "forced_select":
        return {
          selectedIndex: 0,
        };
      default:
        throw new Error();
    }
  };

  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");
  const tabPressed = useKeyPress("Tab");
  const shiftPressed = useKeyPress("Shift");
  const spacePressed = useKeyPress(" ");
  const escapePressed = useKeyPress("Escape");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (arrowUpPressed && open) {
      dispatch({ type: "arrowUp" });
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed && open) {
      dispatch({ type: "arrowDown" });
    }
  }, [arrowDownPressed]);

  useEffect(() => {
    if ((enterPressed || spacePressed) && open) {
      if (!list) return;
      const selectedSize = list?.[state?.selectedIndex];
      if (list?.[state?.selectedIndex]?.onClick) {
        list?.[state?.selectedIndex]?.onClick();
      } else if (onChange) {
        onChange(selectedSize);
      }
      setOpen && setOpen();
      id && focusOnElement(id);
    }
  }, [enterPressed, spacePressed]);

  useEffect(() => {
    if (tabPressed && open) {
      setOpen && setOpen();
      if (shiftPressed) {
        onShiftTab && onShiftTab();
      } else {
        onTab && onTab();
      }
    }
  }, [tabPressed, shiftPressed]);

  useEffect(() => {
    if (escapePressed && open) {
      setOpen && setOpen();
      id && focusOnElement(id);
    }
  }, [escapePressed]);

  useEffect(() => {
    if (open) {
      dispatch({ type: isForcedSelect ? "forced_select" : "reset" });
      focusOnElement(
        isForcedSelect ? "inline-dialog-item-0" : "inline-dialog-wrapper"
      );
    }
  }, [open, isForcedSelect]);

  useEffect(() => {
    if (open && !isNull(state?.selectedIndex)) {
      focusOnElement(`inline-dialog-item-${state?.selectedIndex}`);
    }
  }, [state?.selectedIndex]);

  return (
    <>
      {list?.length === 0 && (
        <InlineDialog.MenuItem disabled>
          <p className="typography-body text-n-700 px-[0.625rem] py-[0.5rem] w-full">
            No Option
          </p>
        </InlineDialog.MenuItem>
      )}
      {list?.length > 0 &&
        list?.map((listItem: any, index: number) => (
          <InlineDialog.MenuItem
            key={index}
            useHover={false}
            onClick={() =>
              listItem?.onClick ? listItem.onClick() : onChange(listItem)
            }
            selected={state?.selectedIndex === index}
            index={index}
            customClass={
              listItem?.customClass ? listItem.customClass : menuCustomClass
            }
            disabled={listItem?.disabled}
          >
            {listItem?.customComponent ? (
              listItem?.customComponent
            ) : (
              <p
                className={`typography-body text-n-700 px-[0.625rem] py-[0.5rem] hover:bg-b-100 w-full  ${
                  widthBasedOnChildren ? "whitespace-nowrap" : ""
                }`}
              >
                {useCapitalize
                  ? capitalize(keyList ? listItem[keyList] : listItem)
                  : keyList
                  ? listItem[keyList]
                  : listItem}
              </p>
            )}
          </InlineDialog.MenuItem>
        ))}
    </>
  );
};

export default InlineDialogKeyPress;
