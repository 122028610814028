const tableColumnIdProps = (
  tableId?: string,
  index?: number,
  columnKey?: string
) => ({
  tableId: tableId,
  rowIndex: index,
  columnKey: columnKey,
});

const getHeaderKey = (headers: any[], index: number) => {
  const header = headers[index];
  return (
    header?.headerKey ||
    header?.key + (header?.subKey ? `-${header.subKey}` : "") ||
    index
  );
};

const getHeaderColumnId = (tableId: string, headers: any[], index: number) => {
  return `${tableId}+header-column-${getHeaderKey(headers, index)}`;
};

const getColumnId = (
  tableId: string,
  headers: any[],
  rowIndex: number,
  columnIndex: number
) => {
  const key = getHeaderKey(headers, columnIndex);
  return `${tableId}+row-${rowIndex}+column-${key}`;
};

export { tableColumnIdProps, getHeaderKey, getHeaderColumnId, getColumnId };
